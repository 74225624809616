import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Headline from "../components/headline"

const GraphicContainer = styled.div`
  position: relative;
`

const GraphicTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 50%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    margin: 0;
    padding: 0;
  }
`

const GraphicImage = styled.img`
  width: 100%;
  height: auto;
`

const Graphic = (props) => (
  <StaticQuery
    query={graphql`
      query {
        graphics: allFile {
          edges {
            node {
              relativePath
              publicURL
              name
            }
          }
        }
      }
    `}
    render={(data) => {
      const graphic = data.graphics.edges.find((n) => {
        return n.node.relativePath.includes(props.filename)
      })

      if (!graphic) {
        return null
      }

      return (
        <GraphicContainer id={props.id}>
          {props.text ? (
            <GraphicTitle>
              <Headline primary text={props.text} />
            </GraphicTitle>
          ) : null}
          <GraphicImage src={graphic.node.publicURL} />
        </GraphicContainer>
      )
    }}
  />
)

export default Graphic
