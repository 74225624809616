import React from "react"

import Layout from "../layouts/layout"
import Seo from "../components/seo"

import Text from "../components/text"
import Headline from "../components/headline"
import Block from "../components/block"
import Logo from "../components/logo"
import Graphic from "../components/graphic"

const AhrenshoopPage = () => (
  <Layout>
    <Seo title="Website" />

    <Block>
      <Logo />
      <Headline text="Ortskarte Ahrenshoop" primary />
    </Block>

    <Graphic filename="ahrenshoop.svg"></Graphic>

    <Block>
      <Text>
        <p>
          Zur freien Verwendung - Digital oder Print mit Angabe des
          Copyright-Inhabers (© HAGEN HANSEN).
        </p>
      </Text>
    </Block>
  </Layout>
)

export default AhrenshoopPage
